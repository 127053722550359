import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import {
  Button,
  ButtonStyles,
  ThumbsUpIcon,
  ThumbsDownIcon,
  SendIcon,
  FormModal,
  FinalFormField,
} from "yuka";

import { QUERY_KEYS, useWrite, DataTypes, getRelatedId } from "api";

import {
  KYC_INFORMATION_REQUESTED,
  KYC_APPROVED,
  KYC_INFORMATION_UPDATED,
  KYC_REVIEW,
  TRADING_GROUP_SOURCES,
} from "utils/constants";

const getReviewRequestedStatus = clientProfile => {
  if (clientProfile.kycStatus === KYC_INFORMATION_REQUESTED) {
    return KYC_INFORMATION_UPDATED;
  }
  return KYC_REVIEW;
};

const KYCActions = ({ clientProfile }) => {
  const [showForm, setShowForm] = useState(false);
  let userMetadata = JSON.parse(localStorage.getItem("userMetadata"));

  const sourceId = getRelatedId(clientProfile.source);

  const { onSubmit } = useWrite(QUERY_KEYS.CLIENT_PROFILES.detail(sourceId));
  const updateKycStatus = useCallback(
    (kycStatus, values) =>
      onSubmit({
        apiType: DataTypes.CLIENT_PROFILES,
        apiId: sourceId,
        kycStatus,
        ...values,
      }).then(() => {
        setShowForm(false);
      }),
    [sourceId, onSubmit]
  );

  return (
    <>
      {userMetadata?.canRequestClientKyc && clientProfile.sourceType !== TRADING_GROUP_SOURCES && (
        <Button
          trailingIcon={SendIcon}
          buttonStyle={ButtonStyles.KEY_ACTION}
          onClick={() => updateKycStatus(getReviewRequestedStatus(clientProfile))}
        >
          Request KYC Review
        </Button>
      )}
      {userMetadata?.canReviewSourceKyc && (
        <>
          <Button
            buttonStyle={ButtonStyles.KEY_ACTION}
            leadingIcon={ThumbsUpIcon}
            onClick={() => updateKycStatus(KYC_APPROVED)}
          >
            Approve KYC
          </Button>
          <Button
            buttonStyle={ButtonStyles.KEY_ACTION}
            leadingIcon={ThumbsDownIcon}
            onClick={() => setShowForm(true)}
          >
            Request More Information
          </Button>
          {showForm && (
            <Form onSubmit={values => updateKycStatus(KYC_INFORMATION_REQUESTED, values)}>
              {({ handleSubmit }) => (
                <FormModal
                  title="Requesting More Information"
                  onClose={() => setShowForm(false)}
                  onSubmit={handleSubmit}
                >
                  <FinalFormField
                    type="textarea"
                    name="kycReviewNotes"
                    placeholder="What information is missing?"
                  />
                </FormModal>
              )}
            </Form>
          )}
        </>
      )}
    </>
  );
};

KYCActions.propTypes = {
  clientProfile: PropTypes.shape({
    source: PropTypes.arrayOf(PropTypes.string).isRequired,
    sourceType: PropTypes.string.isRequired,
    kycStatus: PropTypes.string.isRequired,
  }).isRequired,
};

export default KYCActions;
