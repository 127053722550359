import styled from "styled-components";
import { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Card,
  CardStyles,
  Badge,
  Fonts,
  YukaThemeProvider,
  ComponentGroup,
  ActionChip,
  EyeIcon,
  EyeOffIcon,
  UploadIcon,
  ColorPalette,
  HyperLink,
} from "yuka";
import { useWrite, DataTypes, QUERY_KEYS } from "api";
import { ClientDocumentsForm } from "forms/DocumentsForm";
import { KYC_BADGE_MAPPING, KYC_INFORMATION_UPDATED } from "utils/constants";
import { ClientDocumentsTable } from "templates/DocumentsTable";

import { INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES, KYC_APPROVED } from "./constants";

const StyledActions = styled.div`
  padding: 0 16px;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const StyledKYCCard = styled(Card)`
  flex-shrink: 0;

  // Specificity overrides a card style rule that assumes there is a header
  // (there is not in this case).
  > :first-child:not(:last-child) {
    margin-bottom: 8px;
    width: fit-content;
  }
`;

const StyledRerequest = styled(Fonts.Caption2theme50)`
  border-top: 1px solid ${ColorPalette.white15};
  padding-top: 16px;
`;

const DocumentsTab = ({ sourceProfile, documents }) => {
  let userMetadata = JSON.parse(localStorage.getItem("userMetadata"));
  const { id: sourceId } = useParams();
  const [includeArchived, setIncludeArchived] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const queryParams = {
    "filter[isArchived]": includeArchived ? undefined : false,
  };

  const { onSubmit: onSubmitClientProfile } = useWrite(QUERY_KEYS.CLIENT_PROFILES.detail(sourceId));
  const requestReview = useCallback(
    () =>
      onSubmitClientProfile({
        apiType: DataTypes.CLIENT_PROFILES,
        apiId: sourceId,
        kycStatus: KYC_INFORMATION_UPDATED,
      }),
    [sourceId, onSubmitClientProfile]
  );

  const openForm = () => setFormOpen(true);
  const closeForm = () => setFormOpen(false);

  return (
    <ComponentGroup>
      {sourceProfile.kycReviewNotes && sourceProfile.kycStatus !== KYC_APPROVED && (
        <YukaThemeProvider theme={{ surfaceLevel: 2 }}>
          <StyledKYCCard cardStyle={CardStyles.PADDED}>
            <Badge badgeStyle={KYC_BADGE_MAPPING[sourceProfile.kycStatus]}>
              {sourceProfile.kycStatus}
            </Badge>
            <Fonts.Body1theme80 as="p">{sourceProfile.kycReviewNotes}</Fonts.Body1theme80>
            {!userMetadata?.canReviewSourceKyc && (
              <StyledRerequest as="p">
                When you have addressed the feedback,&nbsp;
                <HyperLink url="#" onClick={requestReview}>
                  Request KYC Review
                </HyperLink>
              </StyledRerequest>
            )}
          </StyledKYCCard>
        </YukaThemeProvider>
      )}
      <StyledCard title="Documents" cardStyle={CardStyles.SECTIONED}>
        <StyledActions>
          {includeArchived ? (
            <ActionChip
              onClick={() => setIncludeArchived(prev => !prev)}
              leadingIcon={EyeIcon}
              text="Hide archived documents"
            />
          ) : (
            <ActionChip
              onClick={() => setIncludeArchived(prev => !prev)}
              leadingIcon={EyeOffIcon}
              text="Show archived documents"
            />
          )}
          <ActionChip
            onClick={openForm}
            leadingIcon={UploadIcon}
            text="Upload new client document"
          />
        </StyledActions>
        {formOpen && (
          <ClientDocumentsForm
            closeModal={closeForm}
            isClient={[INDIVIDUAL_SOURCES, ENTITY_SOURCES, TRUST_SOURCES].includes(
              sourceProfile.sourceType
            )}
          />
        )}
        <ClientDocumentsTable documents={documents} queryParams={queryParams} />
      </StyledCard>
    </ComponentGroup>
  );
};

DocumentsTab.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string.isRequired,
    })
  ),
  sourceProfile: PropTypes.shape({
    apiId: PropTypes.string.isRequired,
    kycStatus: PropTypes.string.isRequired,
    kycReviewNotes: PropTypes.string,
    sourceType: PropTypes.string.isRequired,
  }).isRequired,
};

export default DocumentsTab;
